import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ResultServicesService } from 'src/app/servicios/result-services.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  MenJK = '';
  codigo = '';
  constructor( private rService: ResultServicesService){}
  ngOnInit(): void {

  }
}
