<mat-toolbar>
    <span>
        <a mat-button >
         <mat-icon>search </mat-icon>VISUALIZADOR DE RESULTADOS
        </a>
    </span>

    <div *ngIf="okVerBotonPDF">
        <span>

            <a mat-button href="{{rutaPdf}}" >
                <mat-icon>picture_as_pdf</mat-icon>
            </a>

        </span>
      </div>

    
</mat-toolbar>
