import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResultServicesService {

  constructor(private http: HttpClient) { }

  // tslint:disable-next-line: typedef
   getExisteArchivo(fileName: string){
    const path = `https://jkapianaliza.azurewebsites.net/api/result/existearchivo?fileName=${fileName}`;
    return this.http.get(path);
  }
}
// https://jkresultgeslab20210317155749.azurewebsites.net/api/result/existearchivo?fileName=
