import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ResultServicesService} from '../../servicios/result-services.service';

@Component({
  selector: 'app-visualizador',
  templateUrl: './visualizador.component.html',
  styleUrls: ['./visualizador.component.css']
})
export class VisualizadorComponent implements OnInit {

  //@Output() cambioOkPdf = new EventEmitter<boolean>();

  title = 'Resultados';
  pdfSrc = 'https://jkgeslabstorage.blob.core.windows.net/analiza/';
  mensaje = '';
  okPdf:boolean = false;
  auxStr = '';
  id: any;

  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private rServices: ResultServicesService) {
              this.id = this.route.snapshot.paramMap.get('id');
               }

  ngOnInit(): void {
    this.auxStr = 'https://jkgeslabstorage.blob.core.windows.net/analiza/' +  this.id + '.pdf';

    this.rServices.getExisteArchivo(this.id + '.pdf')
    // tslint:disable-next-line: deprecation
    .subscribe(OkArchivo => {
      const retAPI = OkArchivo;
      if (retAPI === true){
        this.okPdf = true;
        //this.cambiarOkPdf();
        this.mensaje = '';
        //console.log("OK PDF Tre");
      }
      else
      {
        this.okPdf = false;
        //this.cambiarOkPdf();
        this.mensaje = 'Resultados aún no disponibles, vuelva a verificar más tarde';
        //console.log("OK PDF False");
      }
    });
    //this.pdfSrc = 'https://jkgeslabstorage.blob.core.windows.net/adventista-asu/' +  this.id + '.pdf';
   // console.log('dire: ' + this.pdfSrc);
  }

  //cambiarOkPdf(){
  //  this.cambioOkPdf.emit(this.okPdf);
  //}
  // https://jkapiadventista.azurewebsites.net/

  /*
  getNombreArch(){
    this.auxStr = 'https://jkgeslabstorage.blob.core.windows.net/adventista-asu/' +  this.id + '.pdf';
    // console.log('dire: ' + this.auxStr );
    return this.auxStr;
  }
  */
}
