<app-header></app-header>

<div>
    <span>
        <a >
            <h1>Resultados aún no disponibles, vuelva a verificar más tarde</h1>
        </a>
    
   </span>
</div>
