<app-header >
</app-header>

<div class="contenedor">

    <h2>Ingrese su código</h2>

    <form (submit)="getOkArchivo()" [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>Ingrese su código</mat-label>
            <input formControlName="codigo" id="inputCodigo" matInput/>
        </mat-form-field>
        <div>
            <i> {{ MenJK }} </i>
        </div>
        <button mat-raised-button color="warn" [disabled]="form.invalid">
            Ver
        </button>
        <a type="button" mat-raised-button color="black" href="">Cancelar</a>
    </form>
   
</div>