import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ResultServicesService} from '../../servicios/result-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public MenJK!: string;

  constructor( private formbuilder: FormBuilder,
               private rServices: ResultServicesService,
               private route: Router
               ) { }

  form!: FormGroup;

  ngOnInit(): void {

    this.form = this.formbuilder.group({
      codigo: ['', {
        validators: [Validators.required]
      }]
    });
  }


  // tslint:disable-next-line: typedef
  visualizar(){
     const valor = this.form.value;
     console.log(valor);

  }
  // tslint:disable-next-line: typedef
  getOkArchivo(){

    const {codigo} = this.form.value; // + '.pdf';
    //console.log(codigo + '.pdf');

    this.rServices.getExisteArchivo(codigo + '.pdf')
    // tslint:disable-next-line: deprecation
    .subscribe(OkArchivo => {
      const retAPI = OkArchivo;
      if (retAPI === true){
        this.route.navigate(['/vis', codigo]);
        //console.log('OK ir a');
      }
      else
      {
        this.MenJK = 'Resultados aún no disponibles, vuelva a verificar más tarde';
        //console.log('NO OK');

      }
      // console.log(OkArchivo);
    });
  }
  // tslint:disable-next-line: typedef
}



