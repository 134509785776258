import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { NotFoundComponent } from './componentes/not-found/not-found.component';
import { VisualizadorComponent } from './componentes/visualizador/visualizador.component';

const routes: Routes = [
    { path: 'vis/:id', component: VisualizadorComponent },
    { path: '', component: InicioComponent},
    { path: 'inicio', component: InicioComponent},
    { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
