import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // tslint:disable-next-line: ban-types
  @Input() okVerBotonPDF: Boolean = false;
  @Input() rutaPdf = '';

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  /*goBack(): void {
    this.location.back();
  }*/

}
