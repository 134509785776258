<app-header 
  [okVerBotonPDF] = "okPdf"
  [rutaPdf] = "auxStr"
></app-header>

<body>
  <div *ngIf="!okPdf">
    <h3>
      {{mensaje}}
    </h3>
  </div>

    <div>
      <p *ngIf="okPdf">

        <pdf-viewer
        [src]="auxStr"
        [render-text]="true"
        style="display: block;"
        [fit-to-page]="true"
        >
      </pdf-viewer>

      </p>
      
       
    </div>

    
  </body>
  
